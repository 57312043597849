@use 'colors.scss';
.current-runner {
    position: absolute;
    z-index: 100;
    width: 671px;
    height: 70px;
    font-family: teko;
    color: colors.$main-accent;
    @include colors.glow;
    font-size: 36px;
    background-color: colors.$panel-background;
    .frame {
        position: absolute;
        z-index: 110;
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        text-align: center;
        top: 2px;
        .runner-name {
            opacity: 0;
        }
    }
    .social {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
        display: flex;
        flex-flow: column;
        justify-content: center;
        text-align: center;
        top: 2px;
        .handle {
            opacity: 0;
        }
        .icon {
            position: absolute;
            height: 54px;
            width: 41px;
            top: 6px;
            left: 59px;
        }
    }
    .runner-name {
        text-transform: uppercase;
    }
}
.current-runner-1 {
    top: 340px;
}

.current-runner-2 {
    top: 415px;
}