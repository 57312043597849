* {
  image-rendering: pixelated;
}

.current-runner {
  z-index: 100;
  color: #e2881d;
  text-shadow: 0 0 8px #e2881d00;
  background-color: #100b1a;
  width: 671px;
  height: 70px;
  font-family: teko;
  font-size: 36px;
  position: absolute;
}

.current-runner .frame {
  z-index: 110;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 2px;
}

.current-runner .frame .runner-name {
  opacity: 0;
}

.current-runner .social {
  z-index: 100;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 2px;
}

.current-runner .social .handle {
  opacity: 0;
}

.current-runner .social .icon {
  width: 41px;
  height: 54px;
  position: absolute;
  top: 6px;
  left: 59px;
}

.current-runner .runner-name {
  text-transform: uppercase;
}

.current-runner-1 {
  top: 340px;
}

.current-runner-2 {
  top: 415px;
}
/*# sourceMappingURL=repo.fd489a55.css.map */
